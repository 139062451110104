import { STORE_LOGIN_STATE, STORE_TOKEN } from '../actions/auth.actions'

const initialState = {
    user: null,
    token: null,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_LOGIN_STATE:
            return { ...state, user: action.payload }

        case STORE_TOKEN:
            return { ...state, token: action.payload }
        default:
            return state
    }
}

export default authReducer
