import React, { Suspense,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './assets/css/assignFields.css'
const Post = React.lazy(() => import('./components/blocks/Post'));
const Posts = React.lazy(() => import('./components/blocks/Posts'));
const SecuredRoute = React.lazy(() => import('./components/common/SecuredRoute'));
const WelcomePageWrapper = React.lazy(() => import('./components/common/WelcomePageWrapper'));
const About = React.lazy(() => import('./components/pages/About'));
const Blog = React.lazy(() => import('./components/pages/Blog'));
const ConfirmEmail = React.lazy(() => import('./components/pages/ConfirmEmail'));
const Contact = React.lazy(() => import('./components/pages/Contact'));
const Dashaboard = React.lazy(() => import('./components/pages/Dashaboard'));
const Documentations = React.lazy(() => import('./components/pages/Documentations'));
const Faq = React.lazy(() => import('./components/pages/Faq'));
const Features = React.lazy(() => import('./components/pages/Features'));
const Forgotpassword = React.lazy(() => import('./components/pages/Forgotpassword'));
const Home = React.lazy(() => import('./components/pages/Home'));
const Login = React.lazy(() => import('./components/pages/Login'));
const PageNotFound = React.lazy(() => import('./components/pages/PageNotFound'));
const PreviewFields = React.lazy(() => import('./components/pages/PreviewFields'));
const Signup = React.lazy(() => import( './components/pages/Signup'));
const VerifyEmail = React.lazy(() => import( './components/pages/VerifyEmail'));

const DashboardPageWrapper = React.lazy(() => import( './components/common/DashboardPageWrapper'));
const AssignFields = React.lazy(() => import( './components/pages/AssignFields'));
const BlockchainDocPreview = React.lazy(() => import( './components/pages/BlockchainDocPreview'));
const BlockchainDocPreviewSingle = React.lazy(() => import( './components/pages/BlockchainDocPreviewSingle'));
const CreateFields = React.lazy(() => import( './components/pages/CreateFields'));
const CreateNewPassword = React.lazy(() => import( './components/pages/CreateNewPassword'));
const DocPreview = React.lazy(() => import( './components/pages/DocPreview'));
const DocPreviewCombinedSigners = React.lazy(() => import( './components/pages/DocPreviewCombinedSigners'));
const DocPublishing = React.lazy(() => import( './components/pages/DocPublishing'));
const DocSign = React.lazy(() => import( './components/pages/DocSign'));
const Documents = React.lazy(() => import( './components/pages/Documents'));
const PrivacyPolicy = React.lazy(() => import( './components/pages/PrivacyPolicy'));
const Profile = React.lazy(() => import( './components/pages/Profile'));
const PublishedDocs = React.lazy(() => import( './components/pages/PublishedDocs'));
const SelectSigners = React.lazy(() => import( './components/pages/SelectSigners'));
const SharedDoc = React.lazy(() => import( './components/pages/SharedDoc'));
const SignDocBlockchain = React.lazy(() => import( './components/pages/SignDocBlockchain'));
const SignersForBlockchainFlow = React.lazy(() => import( './components/pages/SignersForBlockchainFlow'));
const TermsAndConditions = React.lazy(() => import( './components/pages/TermsAndConditions'));
const VerifyAccount = React.lazy(() => import( './components/pages/VerifyAccount'));
const ViewPublishedDoc = React.lazy(() => import( './components/pages/ViewPublishedDoc'));
const ViewSharedDoc = React.lazy(() => import( './components/pages/ViewSharedDoc'));
const Testimonial = React.lazy(() => import( './components/pages/Testimonial'));
const VerifySignature = React.lazy(() => import( './components/common/VerifySignature'));
const ViewBlockchainDocs = React.lazy(() => import( './components/pages/ViewBlockchainDocs'));
const Solutions = React.lazy(() => import( './components/pages/Solutions'));
const ViewSigningDocs = React.lazy(() => import( './components/pages/ViewSigningDocs'));



function App() {
    return (
        <>
         <Suspense fallback={<div>.</div>}>
       
         <Router>
                <Routes>
                    <Route element={<WelcomePageWrapper />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/features" element={<Features />} />
                        <Route path="/documentations" element={<Documentations />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/solutions" element={<Solutions />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/Testimonial" element={<Testimonial />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/forgotpassword" element={<Forgotpassword />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/blog" element={<Blog />}>
                            <Route path="" element={<Posts />} />
                            <Route path=":postSlug" element={<Post />} />
                        </Route>
                        <Route path="/verifyEmail" element={<VerifyEmail />} />
                        <Route path="/confirm" element={<ConfirmEmail />} />
                        <Route path="/resetPassword" element={<CreateNewPassword />} />
                        <Route path="/AccountVerify" element={<VerifyAccount />} />
                        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    </Route>

                    {/* loggedIn Routes */}
                    <Route element={<SecuredRoute />}>
                        <Route element={<DashboardPageWrapper />}>
                            <Route path="/dashboard" element={<Dashaboard />} />
                            <Route path="/preview" element={<PreviewFields />} />
                            <Route path="/selectSigners" element={<SelectSigners />} />
                            <Route path="/SignersForBlockchainFlow" element={<SignersForBlockchainFlow />} />
                            <Route path="/publishDoc" element={<DocPublishing />} />
                            <Route path="/shareDoc" element={<SharedDoc />} />
                            <Route path="/docs" element={<Documents />} />
                            <Route path="/profile" element={<Profile />} />
                        </Route>

                        <Route path="/assignFields" element={<AssignFields />} />
                        <Route path="/createFields" element={<CreateFields />} />
                        <Route path="/signDoc/:id" element={<DocSign />} />
                        <Route path="/signDocBlockchain/:id" element={<SignDocBlockchain />} />
                        <Route path="/docPreview/:id" element={<DocPreview />} />
                        <Route path="/BlockchainDocPreview/:id" element={<BlockchainDocPreview />} />
                        <Route path="/DocPreviewCombinedSigners/:id" element={<DocPreviewCombinedSigners />} />

                        <Route path="/BlockchainDocSinglePreview/:id" element={<BlockchainDocPreviewSingle />} />
                        <Route path="/viewSharedDoc/:id" element={<ViewSharedDoc />} />
                        <Route path="/viewBlocakchainDoc/:id" element={<ViewBlockchainDocs />} />
                        <Route path="/viewSigningDoc/:id" element={<ViewSigningDocs />} />
                        <Route path="/verifySignature" element={<VerifySignature />} />
                    </Route>
                    {/* End of loggedIn Routes */}
                    <Route path="*" element={<PageNotFound />} />
                    {/* public routes */}
                    <Route path="/published-docs" element={<PublishedDocs />} />
                    <Route path="/published-docs/:id" element={<ViewPublishedDoc />} />
                </Routes>
            </Router>
      </Suspense>
        </>
    )
}
export default App
