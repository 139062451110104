import { SET_RECEIVER_METADATA } from '../actions/receiverMetadata.action'

const receiverMetadata = null

const receiverMetadataReducer = (state = receiverMetadata, action) => {
    switch (action.type) {
        case SET_RECEIVER_METADATA:
            state = action.payload
            return state
        default:
            return state
    }
}

export default receiverMetadataReducer
