export const SET_PDF_UPLOAD = 'SET_PDF_UPLOAD'
export const SET_DOC_SIGNING_TYPE = 'SET_DOC_SIGNING_TYPE'
export const SET_SIGNERS_LIST = 'SET_SIGNERS_LIST'
export const SET_SIGNFIELD_METADATE = 'SET_SIGNFIELD_METADATE'
export const SET_PDF_IMAGE_ARRAY = 'SET_PDF_IMAGE_ARRAY'
export const SET_ORIGINAL_PDF = 'SET_ORIGINAL_PDF'

export const setUploadPdf = (payload) => {
    return {
        type: SET_PDF_UPLOAD,
        payload: payload,
    }
}

export const setDocSigningType = (payload) => {
    return {
        type: SET_DOC_SIGNING_TYPE,
        payload: payload,
    }
}

export const setSigners = (payload) => {
    return {
        type: SET_SIGNERS_LIST,
        payload: payload,
    }
}

export const setPdfImagesArray = (payload) => {
    return {
        type: SET_PDF_IMAGE_ARRAY,
        payload: payload,
    }
}

export const setOriginalPdfArray = (payload) => {
    return {
        type: SET_ORIGINAL_PDF,
        payload: payload,
    }
}