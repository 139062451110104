import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import './index.css'
import './media.css'
import store from './redux/store'
import { MetamaskStateProvider } from 'use-metamask'
import reportWebVitals from './reportWebVitals'
window.Buffer = window.Buffer || require('buffer').Buffer

const backup = console.warn

console.warn = function filterWarnings(msg) {
    const supressedWarnings = [
        'validateDOMNesting(...):',
        'The message port closed before a response was received',
        'validateDOMNesting(...): <tr> cannot appear as a child of <td>',
        '[DOM] Password field is not contained',
    ]

    if (!supressedWarnings.some((entry) => msg.includes(entry))) {
        backup.apply(console, arguments)
    }
}

ReactDOM.render(
    //<React.StrictMode>
    <Provider store={store}>
        <MetamaskStateProvider>
            <App />
        </MetamaskStateProvider>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root'),
)

reportWebVitals()
