import { SET_SIGNING_METADATA } from '../actions/signingMetadata.actions'

const documentMetadata = null

const signingMetadataReducer = (state = documentMetadata, action) => {
    switch (action.type) {
        case SET_SIGNING_METADATA:
            state = action.payload
            return state
        default:
            return state
    }
}

export default signingMetadataReducer
