import { DOCUMENT_SIGNING } from '../../utils/constants'
import {
    SET_DOC_SIGNING_TYPE,
    SET_PDF_IMAGE_ARRAY,
    SET_PDF_UPLOAD,
    SET_SIGNERS_LIST,
} from '../actions/pdfupload.actions'

const initialState = {
    pdfFile: null,
    signingType: DOCUMENT_SIGNING,
    signersList: [],
    pdfImagesList: [],
}

const pdfUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PDF_UPLOAD:
            return { ...state, pdfFile: action.payload }
        case SET_DOC_SIGNING_TYPE:
            return { ...state, signingType: action.payload }
        case SET_SIGNERS_LIST:
            return { ...state, signersList: action.payload }
        case SET_PDF_IMAGE_ARRAY:
            return { ...state, pdfImagesList: action.payload }
        default:
            return state
    }
}

export default pdfUploadReducer
