export const KEY_USER = 'meafjnHZVw0'
export const KEY_TOKEN = 'abbdJ4Yfm54'

export const DOCUMENT_SIGNING = 'Document Signing'
export const DOCUMENT_SIGNING_WITH_BLOCKCHAIN = 'Document Signing with Blockchain'
export const DOCUMENT_PUBLISHING = 'Document Publishing'
export const DOCUMENT_SHARING = 'Document Sharing'

export const ALERT_MESSAGE_TEXT = 'ALERT_MESSAGE_TEXT'

export const FieldTypes = {
    TEXT_INPUT: 'Text Inputs',
    DATES: 'Dates',
    TAGS: 'Tags',
    INITIALS: 'Initials',
    SIGNATURE: 'Signatures',
    CHECKBOX: 'CheckBox',
}

export const FontList = [
    {
        id: 1,
        name: 'Arial',
        fontFamily: 'Arial',
    },
    {
        id: 2,
        name: 'Calibri',
        fontFamily: 'calibri',
    },
    {
        id: 3,
        name: 'Roboto',
        fontFamily: 'roboto',
    },
    {
        id: 4,
        name: 'Verdana',
        fontFamily: 'verdana',
    },
]

export const FontSize = [
    {
        id: 1,
        size: 12,
    },
    {
        id: 2,
        size: 14,
    },
    {
        id: 3,
        size: 16,
    },
    {
        id: 4,
        size: 18,
    },
    {
        id: 5,
        size: 20,
    },
    {
        id: 6,
        size: 22,
    },
]

export const SIGNER_COLORS = [
    '#fe9c8f',
    '#fec8c1',
    '#a7d6d7',
    '#e7dce0',
    '#e095f7',
    '#f2c14e',
    '#FCF5C7',
    '#FFC09F',
    '#79ADDC',
]

export const KEY_METAMASK_INFO = 'KEY_METAMASK_INFO'
